<template>
  <div id="map" ref="map-root" style="width: 100%; height: 100%">
  </div>
</template>

<script>

import proj4 from 'proj4'

export default {
  name: 'MapContainer',
  components: {},
  props: {
    markerPos: {
      type: Array,
      default () {
        return []
      }
    },
    resetMarker: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      map: null,
      layer: null,
      center: null
    }
  },
  methods: {
    initiateMap () {
      this.map = new ol.Map({
        target: 'map',
        layers: [
          new ol.layer.Tile({
            source: new ol.source.XYZ({
              url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              maxZoom: 19
            })
          }),
          new ol.layer.Tile({
            source: new ol.source.XYZ({
              url: 'https://podvelka.webgis.si/tiles/{z}/{x}/{-y}.png',
              minZoom: 15
            })
          })
        ],
        view: new ol.View({
          center: ol.proj.fromLonLat([15.317768392781069, 46.595706343051745]),
          extent: [1705079.7380436575, 5876253.454405137, 1705212.1111286636, 5876400.994390579],
          minZoom: 19,
          maxZoom: 25,
          zoom: 19,
          rotation: 3.14
        })
      })
      const view = this.map.getView()
      this.center = view.getCenter()
      /*
      this.map.on('moveend', () => {
        const view = this.map.getView()
        // const center = view.getCenter()
        // console.log(center) //  formatCoordinate(center);
      })
      */
      const point = this.transformLatLong('161351.870000000000000', '524687.250000000000000')
      const markers = new ol.layer.Vector({
        source: new ol.source.Vector(),
        style: new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0.5, 1],
            src: '/img/marker_moder.png'
          })
        })
      })
      this.map.addLayer(markers)
      const marker = new ol.Feature(new ol.geom.Point(point))
      markers.getSource().addFeature(marker)
    },
    transformLatLong (y, x) {
      return ol.proj.transform([parseFloat(x) + 3, parseFloat(y) - 2], 'EPSG:3912', 'EPSG:3857')
    },
    setMarker (position) {
      const point = this.transformLatLong(position[1], position[0])
      const markers = new ol.layer.Vector({
        source: new ol.source.Vector(),
        style: new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0.5, 1],
            src: '/img/marker.png'
          })
        })
      })
      this.map.addLayer(markers)
      this.layer = markers

      const marker = new ol.Feature(new ol.geom.Point(point))
      markers.getSource().addFeature(marker)

      this.map.getView().animate({
        center: point,
        zoom: 20,
        duration: 2000
      })
    },
    backToCenter () {
      const view = this.map.getView()
      view.setZoom(19)
      view.setCenter(this.center)
    },
    removeMarker () {
      if (this.layer) {
        this.map.removeLayer(this.layer)
        this.layer = null
      }
      // this.backToCenter()
    }
  },
  async mounted () {
    proj4.defs(
      'EPSG:3912',
      '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500375 +y_0=-4999962.5 +ellps=bessel +units=m +no_defs'
    )
    proj4.defs(
      'EPSG:3794',
      '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
    )
    ol.proj.proj4.register(proj4)

    await this.initiateMap()
  },
  watch: {
    markerPos (val) {
      if (val.length > 0) {
        this.removeMarker()
        this.setMarker(val)
        this.$emit('setOK')
      }
    },
    resetMarker (val) {
      if (val) {
        this.removeMarker()
        // this.backToCenter()
        this.$emit('resetOK')
      }
    }
  }
}
</script>
