<template>
  <div class="infos-tab">
    <div class="infos-results" style="width:100%;">
      <div v-for="(info, key) in infosResults" :id="key" :key="key" :class="(key === selectedInfoKey)?'btn-next':'btn-prev'" style="width:100%;font-size:25px" @click="getInfo(info.link, key)">{{info.title}}</div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api'
export default {
  name: 'tabInfo',
  props: {
    value: null
  },
  data () {
    return {
      selectedInfoKey: null,
      infosResults: null
    }
  },
  methods: {
    getInfo (action = 'informacije', key = null) {
      this.$emit('action')
      // console.log(action)
      if (key && this.selectedInfoKey === key) return
      this.selectedInfoKey = key
      api.executeGetServerAction(action)
        .then(response => {
          // console.log('response')
          if (action === 'informacije') {
            this.infosResults = response.data
            if (this.infosResults) this.getInfo(this.infosResults[0].link, 0)
          } else {
            // console.log(response.data.tekst)
            if (this.value) this.value.text = response.data.tekst
          }
        })
        .catch(error => {
          console.log('LOADIG INFO error!')
          console.log('Error: ' + error)
          if (this.value) this.value.text = null
        })
    }
  },
  created () {
    this.getInfo()
  }
}
</script>
<style scoped>
  .active-info {
    color: #22395d;
    background-color: #f3f3f3;
  }
</style>
