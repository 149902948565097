<template>
  <div class="buttons">
    <div class="button" :class="(showSearch)?'active':''" @click="$emit('searchClick')">
      <img class="svg-search" v-if="showSearch" src="@/assets/img/lupa.svg" alt="iskanje">
      <img class="svg-search" v-else src="@/assets/img/lupa_active.svg" alt="iskanje">
      ISKANJE
    </div>
<!--
    <div class="button" :class="(showNews)?'active':''" @click="$emit('newsClick')">
      <img class="svg-news" v-if="showNews" src="@/assets/img/novice.svg" alt="novice">
      <img class="svg-news" v-else src="@/assets/img/novice_active.svg" alt="novice">
      NOVICE
    </div>
-->
    <div class="button" :class="(showObituaries)?'active':''" @click="$emit('obituariesClick')">
      <img class="svg-obituaries" v-if="showObituaries" src="@/assets/img/osmrtnice.svg" alt="osmrtnice">
      <img class="svg-obituaries" v-else src="@/assets/img/osmrtnice_active.svg" alt="osmrtnice">
      OSMRTNICE
    </div>
    <div class="button" :class="(showInfos)?'active':''" @click="$emit('infoClick')">
      <img class="svg-infos" v-if="showInfos" src="@/assets/img/info.svg" alt="osmrtnice">
      <img class="svg-infos" v-else src="@/assets/img/info_active.svg" alt="osmrtnice">
      INFORMACIJE
    </div>
    <!--
    <div class="button" :class="(showObituaries)?'active':''" @click="$emit('obituariesClick')">
      <img class="svg-obituaries" v-if="showObituaries" src="@/assets/img/osmrtnice.svg" alt="osmrtnice">
      <img class="svg-obituaries" v-else src="@/assets/img/osmrtnice_active.svg" alt="osmrtnice">
      INFORMACIJE
    </div>
    -->
  </div>
</template>

<script>
export default {
  name: 'ActionButtons',
  props: {
    showSearch: {
      type: Boolean,
      default: false
    },
    showNews: {
      type: Boolean,
      default: false
    },
    showObituaries: {
      type: Boolean,
      default: false
    },
    showInfos: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .main-button {
     width:110%;
     height:140px;
     background:white;
     margin-left: 30px;
     margin-bottom:26px;
     border-radius:70px;
     padding:12px;
     color: #22395d;
     font-weight: 700;
     font-size: 35px;
     cursor: pointer;
  }
  .main-buttons {
    float:left;width:390px;height:auto;
  }
  .main-button img {
    float:left;height:100%;text-align: center;
  }
  .main-button .text {
     float:left;padding-left:20px;padding-top:35px;
  }
  .main-picture {
    float: right;
    width: 940px;
    height: auto;
    padding: 15px;
    border-radius:20px;
    background: rgba(255,255,255,0.5);
    margin-top: -10px;
    margin-right: 40px;
    padding-bottom: 10px;
  }
  .main-picture img {
    width: 100%;
  }
</style>
