<template>
  <div class="search-tab">
    <div class="search">
      <input id="search" ref="search" type="text" v-model="searchText"/>
      <div class="middle"></div>
    </div>
    <div class="search-btn" @click="getSearch">
      <img src="@/assets/img/lupa.svg" alt="iskanje">
    </div>
    <div class="search-results">
      <div v-for="(person, key) in personsToShow" :key="key" id="key" class="search-result-box" :class="(key === selectedPersonKey)?'active':''" @click="showGrawe(key, person)">
        <span style="font-weight:700;">{{person.ime_priimek}}</span><br>
        <i>{{person.letnice}}</i><br><br>
        Pokopališče: {{person.pokopalisce}}<br>
        Lokacija: {{person.lokacija}}
      </div>
    </div>

    <pagination v-model="paging" :pages="totalPages" @action="$emit('action');$emit('clear')" />
  </div>
</template>

<script>
import api from '@/backend-api'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'tabSearch',
  components: {
    Pagination
  },
  data () {
    return {
      searchText: '',
      searchResults: [],
      selectedPersonKey: null,
      paging: {
        page: 1,
        items: 6
      }
    }
  },
  methods: {
    focusInput () {
      this.$refs.search.focus()
    },
    showGrawe (key, person) {
      this.$emit('action')
      if (this.selectedPersonKey === key) {
        this.$emit('clear')
        this.resetSelection()
        return
      }
      this.selectedPersonKey = key
      this.$emit('setPos', [person.x, person.y])
    },
    getSearch () {
      this.$emit('action')
      let action = 'pokopani'
      if (this.searchText.length > 1) action = 'pokopani?iskanje=' + this.searchText
      api.executeGetServerAction(action)
        .then(response => {
          this.searchResults = response.data
          this.paging.page = 1
          this.resetSelection()
        })
        .catch(error => {
          console.log('LOADIG POKOPANI error!')
          console.log('Error: ' + error)
        })
    },
    resetSelection () {
      this.selectedPersonKey = null
      this.$emit('clear')
    }
  },
  computed: {
    personsToShow () {
      if (!this.totalPages) return []
      if (this.searchResults.length < 7) return this.searchResults
      const start = (this.paging.page - 1) * this.paging.items
      const end = start + this.paging.items
      this.resetSelection()
      return this.searchResults.slice(start, end)
    },
    totalPages () {
      if (this.searchResults.length === 0) return null
      return Math.floor(this.searchResults.length / this.paging.items) + 1
    }
  },
  watch: {
    searchText (newVal) {
      if (newVal.length > 1 || newVal.length === 0) this.getSearch()
      this.resetSelection()
    }
  },
  created () {
    this.getSearch()
  },
  mounted () {
    this.focusInput()
  }
}
</script>
