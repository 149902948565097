import axios from 'axios'
// import store from '@/store'

const baseUrl = 'https://podvelka-api.webgis.si/v1/' // DEVELOPMENT
const corsDomain = 'https://podvelka-api.webgis.si/' // DEVELOPMENT
// const corsDomain = '*' // DEVELOPMENT

export default {
  executeGetServerAction (action, data = null, auth = null, multipart = null) {
    action = baseUrl + action
    const authHeader = { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': corsDomain } }
    if (!auth && multipart) {
      authHeader.headers = { 'Content-Type': 'multipart/form-data' } // , 'Authorization': 'Bearer ' + store.getters.getToken }
    }
    return axios.get(action, data, authHeader, { withCredentials: true })
  },
  executePutServerAction (action, data = null, auth = null, multipart = null) {
    action = baseUrl + action
    const authHeader = { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': corsDomain } }
    if (!auth && multipart) {
      authHeader.headers = { 'Content-Type': 'multipart/form-data' } // , 'Authorization': 'Bearer ' + store.getters.getToken }
    }
    return axios.put(action, data, authHeader, { withCredentials: true })
  }
}
