<template>
  <section>
    <div class="main-buttons">
      <div class="main-button" @click="$emit('searchClick')">
        <img src="@/assets/img/prva-iskanje.svg" alt="iskanje">
        <div class="text">ISKANJE</div>
      </div>
      <!--
      <div class="main-button" @click="$emit('newsClick')">
        <img src="@/assets/img/prva-novice.svg" alt="novice">
        <div class="text">NOVICE</div>
      </div>
      -->
      <div class="main-button" @click="$emit('obituariesClick')">
        <img src="@/assets/img/prva-osmrtnice.svg" alt="osmrtnice">
        <div class="text">OSMRTNICE</div>
      </div>
      <div class="main-button" @click="$emit('infoClick')">
        <img src="@/assets/img/prva-info.svg" alt="informaciije">
        <div class="text">INFORMACIJE</div>
      </div>
    </div>
    <div class="main-picture">
      <img src="@/assets/img/slika_prva.jpg" alt="pokopališčše">
    </div>
  </section>
</template>

<script>
export default {
  name: 'FirstPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .main-button {
     width:100%;
     height:140px;
     background:white;
     margin-left: 30px;
     margin-bottom:26px;
     border-radius:70px;
     padding:12px;
     color: #22395d;
     font-weight: 700;
     font-size: 35px;
     cursor: pointer;
  }
  .main-buttons {
    float:left;width:410px;height:auto;
  }
  .main-button img {
    float:left;height:100%;text-align: center;
  }
  .main-button .text {
     float:left;padding-left:20px;padding-top:35px;
  }
  .main-picture {
    float: right;
    width: 940px;
    height: auto;
    padding: 15px;
    border-radius:20px;
    background: rgba(255,255,255,0.5);
    margin-top: -10px;
    margin-right: 40px;
    padding-bottom: 10px;
  }
  .main-picture img {
    width: 100%;
  }
</style>
