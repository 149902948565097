<template>
  <div id="app">
    <page-header @homeClick="openHome"/>
    <first-page
      v-if="showHome"
      @searchClick="openSearch"
      @newsClick="openNews"
      @obituariesClick="openObituaries"
      @infoClick="openInfos"
    />
    <action-buttons v-if="!showHome"
      :showSearch="showSearch"
      :showNews="showNews"
      :showObituaries="showObituaries"
      :showInfos="showInfos"
      @searchClick="openSearch"
      @newsClick="openNews"
      @obituariesClick="openObituaries"
      @infoClick="openInfos"
    />

    <div v-if="!showHome" class="main-search">
      <div class="content" :style="(showInfos)?'width:395px;':''">
        <div class="title">{{title}}</div>
        <tab-search v-if="showSearch" @action="resetTimeout" @clear="resetMap=true" @setPos="setMarkerPosition"/>
        <tab-news v-if="showNews" />
        <tab-obituaries v-if="showObituaries" />
        <tab-infos v-if="showInfos" v-model="info" />
      </div>
      <img v-if="!showInfos && !showSearch" src="@/assets/img/slika_pokopalisce_air.jpg" style="float:right;" alt="pokopališče">
<!--
      <img v-if="!showInfos" src="@/assets/img/slika_pokopalisce_air.jpg" style="float:right;" alt="pokopališčše">
-->
      <div v-if="!showSearch && !showObituaries" style="float:right;width:953px;height:715px;background:#f3f3f3;border-radius:20px;padding:20px;color:#22395d;overflow-y: auto;margin-top:60px;" v-html="info.text"></div>
      <div v-show="showSearch" style="float:right;width:713px;height:715px;background:#f3f3f3;color:#22395d;overflow-y: auto;margin-top:60px;">
        <app-map :resetMarker="resetMap" :markerPos="setMap" @resetOK="resetMap=false" @setOK="setMap=[]" />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api'
import FirstPage from './components/FirstPage.vue'
import PageHeader from './components/PageHeader.vue'
import ActionButtons from './components/ActionButtons.vue'
import TabSearch from './components/tabSearch.vue'
import TabObituaries from './components/tabObituaries.vue'
import TabInfos from './components/tabInfos.vue'
import AppMap from './components/MapContainer.vue'

export default {
  name: 'App',
  components: {
    ActionButtons, PageHeader, FirstPage, TabSearch, TabObituaries, TabInfos, AppMap
  },
  data () {
    return {
      coords: {
        xPos: 0,
        yPos: 0
      },
      setMap: [],
      resetMap: false,
      newsResults: [
        { id: 1, title: 'Pogrebi v času korone', date: '11. 4. 2021', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dignissim eu justo ut imperdiet. Vestibulum ultrices condimentum aliquet. In laoreet ac nunc nec dignissim. Nunc viverra vel eros a vehicula. Integer a lacus gravida, ultrices purus sed, mattis eros. Donec mi urna, vestibulum id pretium id, suscipit id diam. Aliquam erat volutpat.<br><br>Cras a mi vel sem malesuada interdum. Sed tincidunt orci vel dui gravida, at venenatis nibh cursus. Phasellus quis enim a purus suscipit eleifend ultrices accumsan libero. Vestibulum libero justo, condimentum id pharetra eu, rhoncus sed felis. Suspendisse ut mollis magna, eget auctor neque. Nam quam magna, placerat sed augue malesuada, feugiat sollicitudin lectus. Nunc euismod eleifend ante ut laoreet. Vivamus elementum tristique congue.' }
      ],
      title: 'ISKANJE',
      showHome: true,
      showSearch: true,
      showNews: false,
      showObituaries: false,
      showInfos: false,
      selectedInfo: 1,
      info: { text: null },
      timeout: null
    }
  },
  methods: {
    reset_Map () {
      this.resetMap = true
    },
    setMarkerPosition (position) {
      this.setMap = position
    },
    openHome () {
      this.showSearch = false
      this.showNews = false
      this.showObituaries = false
      this.showInfos = false
      this.showHome = true
      this.resetTimeout()
    },
    openSearch () {
      this.showHome = false
      this.showSearch = true
      this.title = 'ISKANJE'
      this.showNews = false
      this.showObituaries = false
      this.showInfos = false
      this.resetTimeout()
    },
    openNews () {
      this.showHome = false
      this.title = 'NOVICE'
      this.showSearch = false
      this.showNews = true
      this.showObituaries = false
      this.showInfos = false
      this.resetTimeout()
    },
    openObituaries () {
      this.showHome = false
      this.title = 'OSMRTNICE'
      this.showSearch = false
      this.showNews = false
      this.showObituaries = true
      this.showInfos = false
      this.resetTimeout()
    },
    openInfos () {
      this.showHome = false
      this.title = 'INFORMACIJE'
      this.showSearch = false
      this.showNews = false
      this.showObituaries = false
      this.selectedInfo = 1
      this.showInfos = true
      this.resetTimeout()
    },
    resetTimeout () {
      // console.log('TIMEOUT')
      if (!this.timeout) {
        this.timeout = setTimeout(() => {
          if (!this.showHome) this.openHome()
        }, 120000)
      } else {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!this.showHome) this.openHome()
        }, 120000)
      }
    },
    reportScreenSizeChange () {
      // Report new app size to api
      const appWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const appHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      const action = 'screensize?swidth=' + appWidth + '&sheight=' + appHeight
      api.executeGetServerAction(action)
        .then(response => {
        })
        .catch(error => {
          console.log('SENDING SCREENSIZE error!')
          console.log('Error: ' + error)
        })
    }
  },
  mounted () {
    this.resetTimeout()
    this.reportScreenSizeChange()
  },
  created () {
    window.addEventListener('resize', () => {
      this.reportScreenSizeChange()
    })
  }
}
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  body {
    line-height: 1.4;
    background: url(./assets/img/ozadje.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #app {
    font-family: 'Roboto', sans-serif;
    width:1480px;margin:auto;
    position: relative;
  }
  header {
    float:left;
    width:100%;
    height: auto;
    padding-top: 20px;
    padding-left: 29px;
    padding-right: 29px;
  }
  section {
    float:left;
    width:100%;
    height: auto;
    padding-top: 60px;
  }
  .buttons {
    position:absolute;width:auto;height:140px;top:10px;left:500px;font-size:1.2em;font-weight:700;color: white;
  }
  .button {
    float:left;width:140px;height:100%;margin-right:29px;background:rgba(255,255,255,0.5);text-align:center;border-radius: 20px 20px 0 0;text-align:center;
    padding-top:20px;
    cursor: pointer;
  }
  .svg-search {
    width:65px;margin-bottom:10px;
  }
  .svg-news {
    width:90px;margin-top:-10px;margin-bottom:-5px;
  }
  .svg-obituaries, .svg-infos {
    width:75px;margin-bottom:0px;
  }
  .active {
    background: white;
    color: #22395d;
  }
  .main-search {
    float: left;
    width: 1480px;
    height: 800px;
    padding: 15px;
    border-radius:20px;
    background: rgba(255,255,255,1);
    margin-top: 20px;
    margin-right: 40px;
    padding-bottom: 10px;
    padding-left:25px;
  }
  .main-search img {
    height: 100%;
  }
  input, input:focus {
    border: none;
    outline: none;
  }
  .content {
     float:left;width:695px;height:100%;overflow:hidden;
  }
  .content .title {
     float:left;width:100%;font-size:35px;color:#22395d;font-weight:700;margin-top:20px;
  }
  .content .search {
     float:left;width:85%;height:80px;font-size:35px;color:#22395d;font-weight:700;margin-top:15px;padding-top:5px;padding-left:20px;border-radius: 40px 0 0 40px;border:solid 4px #22395d;border-right:white;
  }
  .content .search input {
     font-family: 'Roboto', sans-serif;color:#22395d;height:90%;margin-top:2px;font-size:40px;font-weight:500;
  }
  .content .search .middle {
     float:right;height:93%;width:4px;background:#f3f3f3;
  }
  .content .search-btn {
    float:left;width:15%;height:80px;padding:10px;padding-left:0;margin-top:15px;border-radius: 0 40px 40px 0;border:solid 4px #22395d;border-left:white;text-align:center;
    cursor:pointer;
  }
  .content .search-results {
    float: left;width:110%;height:500px;margin-top:30px;font-size:20px;
  }
  .content .search-results .search-result-box {
     float:left;background:#f3f3f3;width:335px;height:auto;margin-right:20px;margin-bottom:20px;padding:20px;color:#22395d;border-radius:15px;
     line-height: 1.15;
     cursor: pointer;
  }
  .content .search-results .search-result-box.active {
    background-color: #e3e3e3;
  }
  .news-tab .news-results {
    float: left;width:100%;height:auto;margin-top:30px;font-size:20px;
  }
  .news-tab .news-results .news-result-box {
     float:left;background:#f3f3f3;width:100%;height:auto;padding:20px;color:#22395d;border-radius:15px;
     line-height: 1.15;
  }
  .news-tab .news-results .news-result-box .news-title {
    font-weight:700;font-size:25px;margin-bottom:10px;width:100%;
  }

  .obituaries-tab .obituaries-results {
    float: left;width:100%;height:680px;margin-top:25px;font-size:20px;overflow-y: auto;
  }
  .obituaries-tab .obituaries-results .obituaries-result-box {
     float:left;background:#f3f3f3;width:100%;height:330px;padding:20px;color:#22395d;border-radius:15px;margin-bottom:20px;
     line-height: 1.15;
  }
  .obituaries-tab .obituaries-results .obituaries-result-box .obituaries-title {
    font-weight:700;font-size:30px;margin-top:10px;margin-bottom:10px;width:100%;
  }

  .content .pagination {
    float:left;width:100%;height:50px;margin-top:30px;font-size:25px;text-align:center;
  }
  .content .pagination .btn-prev {
    float:left;width:20%;height:100%;background:#f3f3f3;border-radius:25px;color:grey;letter-spacing:2px;padding-top:7px;cursor: pointer;
  }
  .content .pagination .info {
    float:left;width:60%;height:100%;padding-top:7px;color:#22395d;
  }
  .content .pagination .btn-next {
    float:left;width:20%;height:100%;background:#22395d;border-radius:25px;color:white;letter-spacing:2px;padding-top:7px;cursor: pointer;
  }
  .content .infos-tab .btn-prev {
    float:left;width:15%;height:100%;border-radius:25px;color:#22395d;background-color:#f3f3f3;padding:10px 20px; margin-bottom: 10px; cursor: pointer;
  }
  .content .infos-tab .btn-next {
    float:left;width:15%;height:100%;border-radius:25px;color:white;background-color:#22395d;padding:10px 20px; margin-bottom: 10px; cursor: default;
  }
  .content .infos-tab .btn-prev:first-of-type, .content .infos-tab .btn-next:first-of-type {
    margin-top: 25px;
  }
</style>
