<template>
  <div class="pagination">
    <div :class="(value.page === 1)?'btn-prev':'btn-next'" @click="listPrev">&#171; Nazaj</div>
    <div class="info">Stran {{value.page}} od {{pages}}</div>
    <div :class="(value.page < pages)?'btn-next':'btn-prev'" @click="listNext">Naprej &#187;</div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    value: null,
    pages: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
    }
  },
  methods: {
    listPrev () {
      this.$emit('action')
      if (this.value.page === 1) return
      this.value.page--
    },
    listNext () {
      this.$emit('action')
      if (this.value.page < this.pages) this.value.page++
    }
  }
}
</script>
