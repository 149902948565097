<template>
  <header>
    <div class="logo-container" @click="$emit('homeClick')">
      <img src="@/assets/img/grb_podvelka.png">
      <div class="text">
        POKOPALIŠČE
        <br>
        <span style="font-weight:700;">BREZNO</span>
      </div>
    </div>
    <div class="info-container">
      <img src="@/assets/img/jkp-radlje-svetel.png">
      <br>
      <span style="font-weight:700;">T</span>: 031 606 911 ali 041 625 582  |  <span style="font-weight:700;">E</span>: info@jkp-radlje.si
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .logo-container {
    float:left; width:auto; cursor: pointer;
  }
  .logo-container img {
    float:left;height:110px;
  }
  .logo-container .text {
    font-size: 40px;float:left;color:white;line-height: 1.2;margin-left:40px;margin-top:7px;
  }
  .info-container {
    float:right;color:white;font-size: 1.15em;
  }
  .info-container img {
    width:430px;
  }
</style>
